import { AxiosError, AxiosInstance } from 'axios';

import { AmplitudeEvent, EVENTS } from '../@types';
import { OPERATIONS_API_ENDPOINT } from '../constants';
import { getErrorTypeFromErrorMessage } from '../utils';

export const openRobotLid = async (
  operationsApiInstance: AxiosInstance,
  logEvent: (event: AmplitudeEvent) => void,
  serialNo: string
): Promise<void> => {
  const startTs = Date.now();
  try {
    const { data } = await operationsApiInstance.post<void>(`${OPERATIONS_API_ENDPOINT}/robots/${serialNo}/open-lid`);
    logEvent({
      eventName: EVENTS.OPEN_LID_PROFILING,
      params: {
        hook: 'OPEN_LID_PROFILING',
        errorMessage: '',
        timingMs: Date.now() - startTs,
      },
    });
    return data;
  } catch (err) {
    const errorMessage =
      (err as AxiosError<{ message: string }>)?.response?.data?.message ??
      (err as AxiosError<{ message: string }>)?.message ??
      '';
    logEvent({
      eventName: EVENTS.OPEN_LID_PROFILING,
      params: {
        hook: 'OPEN_LID_PROFILING',
        errorMessage,
        errorType: getErrorTypeFromErrorMessage(errorMessage),
        timingMs: Date.now() - startTs,
      },
    });
    throw new Error(errorMessage);
  }
};
