import { useAuth0 } from '@auth0/auth0-react';
import { FullStory } from '@fullstory/browser';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios';
import React, { createContext, useCallback, useContext, useState } from 'react';

import { Delivery, FS_EVENTS, IAppContext } from '../@types';
import { DELIVERIES_BASE_URL, INTEGRATIONS_EU_API_ENDPOINT, OPERATIONS_API_ENDPOINT } from '../constants';

const timeout = 5000;

const deliveriesApi = axios.create({
  baseURL: DELIVERIES_BASE_URL,
  timeout,
});

const operationsApi = axios.create({
  baseURL: OPERATIONS_API_ENDPOINT,
  timeout,
});

const privacyApi = axios.create({
  baseURL: INTEGRATIONS_EU_API_ENDPOINT,
  timeout,
});

export const AppContext = createContext<IAppContext>({
  robotId: '',
  selectedDelivery: null,
  setRobotId: () => undefined,
  setSelectedDelivery: () => undefined,
  lidOpen: false,
  setLidOpen: () => undefined,
  deliveriesApi: {} as AxiosInstance,
  operationsApi: {} as AxiosInstance,
  privacyApi: {} as AxiosInstance,
  canRedirect: true,
  setCanRedirect: () => undefined,
  canReload: true,
  setCanReload: () => undefined,
  partnerIds: [],
  setPartnerIds: () => undefined,
});

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [robotId, setRobotId] = useState<string>('');
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery | null>(null);
  const [lidOpen, setLidOpen] = useState<boolean>(false);
  const [canRedirect, setCanRedirect] = useState<boolean>(true);
  const [canReload, setCanReload] = useState<boolean>(true);
  const [partnerIds, setPartnerIds] = useState<string[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  const accessTokenRequestInterceptor = useCallback(
    async (config: AxiosRequestConfig) => {
      const accessToken = await getAccessTokenSilently();

      if (config.headers) {
        config.headers.authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    [getAccessTokenSilently]
  );

  const onResponse = useCallback((response: AxiosResponse): AxiosResponse => {
    return response;
  }, []);

  const onErrorResponse = useCallback((error: AxiosError | Error): Promise<AxiosError> => {
    if (isAxiosError(error)) {
      const { response } = error;

      if (response?.status === 401) {
        FullStory('trackEvent', {
          name: FS_EVENTS.AUTH_ERROR,
          properties: {
            error: error.message,
          },
        });
      }

      if (response?.data?.message) {
        if (Array.isArray(response.data.message)) {
          error.message = response.data.message[0];
        } else {
          error.message = response.data.message;
        }
      } else if (response?.data?.error?.message) {
        error.message = response.data.error.message;
      }
    }
    return Promise.reject(error);
  }, []);

  operationsApi.interceptors.request.use(accessTokenRequestInterceptor);
  operationsApi.interceptors.response.use(onResponse, onErrorResponse);

  deliveriesApi.interceptors.request.use(accessTokenRequestInterceptor);
  deliveriesApi.interceptors.response.use(onResponse, onErrorResponse);

  privacyApi.interceptors.request.use(accessTokenRequestInterceptor);
  privacyApi.interceptors.response.use(onResponse, onErrorResponse);

  const context: IAppContext = {
    robotId,
    selectedDelivery,
    setRobotId,
    setSelectedDelivery,
    lidOpen,
    setLidOpen,
    deliveriesApi,
    operationsApi,
    privacyApi,
    canRedirect,
    setCanRedirect,
    canReload,
    setCanReload,
    partnerIds,
    setPartnerIds,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContextProvider, useAppContext };
