import React from 'react';

import coco from 'src/assets/coco.png';
import cocoError from 'src/assets/error-coco.png';
import { styled } from 'src/stitches.config';

type Props = {
  errorMessage: string;
  showErrorImage?: boolean;
};

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '$100p',
  padding: '$6',
  maxWidth: '800px',
  margin: 'auto',
});

const ErrorMessage = styled('p', {
  padding: '$8 $0',
  textAlign: 'center',
  fontSize: '$5',
  fontWeight: '600',
  lineHeight: '1.5',
});

const StyledImage = styled('img', {
  height: '200px',
  maxWidth: '300px',
  '@media (min-width: 700px)': {
    height: '100%',
  },
});

const CocoError: React.FC<Props> = ({ errorMessage, showErrorImage = true }) => {
  return (
    <Container>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      {showErrorImage ? (
        <StyledImage src={cocoError.src} alt="coco-error" />
      ) : (
        <StyledImage src={coco.src} alt="coco" />
      )}
    </Container>
  );
};

export default CocoError;
