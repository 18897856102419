export const ORDER_PROVIDER_MAP: Record<string, string> = {
  door_dash: 'DoorDash',
  bringg: 'Bringg',
  olo: 'Olo',
  uber_eats: 'Uber Eats',
  lunchbox: 'Lunchbox',
  chow_now: 'Chow Now',
  chowly: 'Chowly',
  doordash: 'DoorDash',
  revel: 'Revel',
  colony: 'Colony',
  grubhub: 'Grubhub',
  ally: 'Ally',
  toast: 'Toast',
  door_dash_marketplace: 'DoorDash Marketplace',
  uber_eats_marketplace: 'UberEats Marketplace',
};
