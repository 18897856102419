import { styled } from 'src/stitches.config';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
}

const Backdrop = styled('div', {
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 100,
});

const ContentContainer = styled('div', {
  backgroundColor: 'white',
  padding: '1.5rem',
  margin: '1rem',
  borderRadius: '4px',
});

const Modal = ({ children, isOpen }: Props) => {
  if (!isOpen) return null;
  return (
    <Backdrop>
      <ContentContainer>{children}</ContentContainer>
    </Backdrop>
  );
};

export default Modal;
