import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import LocizeHttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

import { Locale } from 'src/@types';
import { LOC_NS } from './types';

const isBrowser = typeof window !== 'undefined';

const PROD_BACKEND = {
  backends: [
    LocalStorageBackend, // primary (pull from localstorage if exists) - persists to localstorage
    LocizeHttpBackend, // secondary (pull from pilot server, where translations are predownloaded on build time)
  ],
};

/*
  On the other hand, dev should definitely pull latest translations where possible. 
*/
const DEV_BACKEND = {
  backends: [
    LocizeHttpBackend, // primary (pull from server, where translations are predownloaded on build time)
  ],
};

if (isBrowser) {
  i18next
    .use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false, // this is a bit spammy; turn it on if there are any internationalization issues
      fallbackLng: Locale.EN,
      supportedLngs: Object.values(Locale),
      interpolation: {
        escapeValue: false,
      },
      ns: Object.values(LOC_NS),
      backend: process.env.NODE_ENV === 'development' ? DEV_BACKEND : PROD_BACKEND,
    });
}

export default i18next;
