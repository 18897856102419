export enum LID_ACTIONS {
  OPEN = 'open',
  CLOSE = 'close',
}

export enum LID_STATE {
  OPENING = 'opening',
  CLOSING = 'closing',
}

export const enum OpenLidError {
  BOT_UNHEALTHY = 'BOT_UNHEALTHY',
  BOT_UNAVAILABLE = 'BOT_UNAVAILABLE',
  BOT_GROUNDED = 'BOT_GROUNDED',
  BOT_OFFLINE = 'BOT_OFFLINE',
  BOT_ON_TRIP = 'BOT_ON_TRIP',
  BOT_NEEDS_MAINTENANCE = 'BOT_NEEDS_MAINTENANCE',
  BOT_NOT_IN_RANGE = 'BOT_NOT_IN_RANGE',
  OTHER = 'OTHER',
}
