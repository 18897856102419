import { AxiosError, AxiosInstance } from 'axios';
import { useQuery } from 'react-query';

import { EVENTS, operationsQueryKeys, QueryConfig } from 'src/@types';
import { useAppContext } from 'src/context';
import useAmplitude from './useAmplitude';

interface Params {
  robotSerial: string;
  config?: QueryConfig<typeof getEta>;
}

const getEta = async (operationsApi: AxiosInstance, robotSerial: string): Promise<number> => {
  const { data: tripId } = await operationsApi.get<{ currentTrip: { eta: number; id: string } }>(
    `/robots/${robotSerial}/tripId`
  );
  if (tripId) {
    const { data: trip } = await operationsApi.get<{ eta: number }>(`/beta/pilots/trip/${tripId}`);
    console.log(`${robotSerial} trip: `, trip);
    return trip.eta;
  }

  throw new Error(`Current trip id not found for robot ${robotSerial}`);
};

export const useEta = ({ robotSerial, config }: Params) => {
  const { logEvent } = useAmplitude();
  const { operationsApi } = useAppContext();

  return useQuery({
    queryKey: operationsQueryKeys.robotEta(robotSerial),
    queryFn: () => getEta(operationsApi, robotSerial),
    onError: (err: unknown) => {
      const errorMessage =
        (err as AxiosError<{ message: string }>)?.response?.data?.message ??
        (err as AxiosError<{ message: string }>)?.message ??
        '';
      logEvent({
        eventName: EVENTS.GET_ROBOT_ETA_ERROR,
        params: { errorMessage },
      });
    },
    onSuccess: (data) => {
      logEvent({
        eventName: EVENTS.GET_ROBOT_ETA_SUCCESS,
        params: {
          eta: data,
        },
      });
    },
    ...config,
  });
};
