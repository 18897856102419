export interface Delivery {
  id: string;
  customerName: string; // redacted for doordash marketplace EU deliveries
  cartTotal: number;
  attempts: Array<string>;
  orderSource: string;
  customerId?: string;
  designatedRobotSerial?: string;
}

export interface DeliveryResponse {
  deliveries: Array<Delivery>;
}

export interface Partner {
  id: string;
  name: string;
  humanReadableName?: string;
}

export interface LoadedDelivery {
  id: string;
  cityId: string;
  hubId: string;
  partnerId: string;
  operationalMode: string;
  providedBy: string;
  currentAttemptStatus: string;
  note: string | null;
  internalNote: string;
  route: {
    points: Array<Array<number>>;
    source: string;
    distance: number;
    travelTime: number;
  };
  orderSource: string;
  cancelReason: string | null;
  cancelReasonComments: string | null;
  denormalizedText: string;
  zendeskTicketId: number;
  slackMessageTs: string;
  slackMessageChannel: string;
  scheduledFor: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface LoadRobotResponse {
  id: string;
  deliveryId: string;
  provider: string;
  status: string;
  context: string;
  requirements: Record<string, unknown>;
  createdAt: string;
  updatedAt: string;
  delivery: LoadedDelivery;
}

export const Source = {
  chow_now: 'chow_now',
  door_dash: 'door_dash',
  doordash: 'doordash',
  uber: 'uber',
  grubhub: 'grubhub',
  square: 'square',
  uber_eats: 'uber_eats',
  postmates: 'postmates',
  coco_mobile_app: 'coco_mobile_app',
  coco_web_storefront: 'coco_web_storefront',
  other: 'other',
  ally: 'ally',
  colony: 'colony',
  bringg: 'bringg',
  olo: 'olo',
  popmenu: 'popmenu',
  ritual: 'ritual',
  toast: 'toast',
  otter: 'otter',
  owner_com: 'owner_com',
  door_dash_marketplace: 'door_dash_marketplace',
  uber_eats_marketplace: 'uber_eats_marketplace',
} as const;
export type Source = (typeof Source)[keyof typeof Source];
