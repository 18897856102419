import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BarLoader } from 'react-spinners';

import { LID_STATE } from 'src/@types';
import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { styled } from 'src/stitches.config';

const Container = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 0',
});

const StateText = styled('p', {
  color: '#6C6C6C',
  margin: '2rem 0',
});

type Props = {
  state: LID_STATE;
  robotId: string;
};

const RobotLoading: FC<Props> = ({ state, robotId }) => {
  const { t } = useTranslation(LOC_NS.ORDERS);

  return (
    <Container>
      <BarLoader color="#F268D9" loading={true} width="50%" />
      <StateText>
        {state === LID_STATE.OPENING ? t(ORDERS_KEYS.OPENING, 'Opening') : t(ORDERS_KEYS.CLOSING, 'Closing')}
        &nbsp;<b>{robotId}</b> {t(ORDERS_KEYS.LID, 'lid')}...
      </StateText>
    </Container>
  );
};

export default RobotLoading;
