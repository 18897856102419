import 'src/i18n/init';
import 'react-toastify/dist/ReactToastify.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { FullStory, init as initFullStory } from '@fullstory/browser';
import { globalCss } from '@stitches/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ComponentType, StrictMode, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { AccessProvider, Breadcrumbs, Navbar } from 'src/components';
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_ORIGIN_URL,
  AWS_ENV,
  FULLSTORY_ORG_ID,
  LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
} from 'src/constants';
import { AppContextProvider } from 'src/context';
import { useAmplitude } from 'src/hooks';

if (typeof window !== 'undefined') {
  initFullStory({
    orgId: FULLSTORY_ORG_ID,
    devMode: process.env.NODE_ENV === 'development',
  });
}

datadogLogs.init({
  clientToken: 'pub35431b1401623678f955307904064d5f',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  forwardConsoleLogs: ['warn', 'error'],
  sessionSampleRate: 100,
  service: 'qr',
  env: AWS_ENV,
  beforeSend: (event) => {
    if (process.env.NODE_ENV !== 'development') {
      event['fsLink'] = FullStory('getSession', { format: 'url' });
    }
    return true;
  },
});

const globalStyles = globalCss({
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    color: '$gray12',
    fontFamily: 'Spartan,system-ui,sans-serif',
  },
});

const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(() => new QueryClient());
  const { init } = useAmplitude();

  useEffect(() => {
    globalStyles();
    init();
  }, [init]);

  return (
    <StrictMode>
      <Head>
        <title>Coco | QR</title>
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: AUTH0_ORIGIN_URL,
          audience: AUTH0_AUDIENCE,
        }}
        cacheLocation="localstorage"
        useRefreshTokens
        useRefreshTokensFallback // https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/30
      >
        <QueryClientProvider client={queryClient}>
          <AccessProvider>
            <AppContextProvider>
              <Navbar />
              <Breadcrumbs />
              <Component {...pageProps} />
            </AppContextProvider>
          </AccessProvider>
        </QueryClientProvider>
      </Auth0Provider>
      <ToastContainer position="top-center" />
    </StrictMode>
  );
};

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
  reactOptions: {
    useCamelCaseFlagKeys: true,
  },
})(App as ComponentType<object>);
