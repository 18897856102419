import { useMutation } from 'react-query';

import { useAppContext } from 'src/context';
import { openRobotLid } from '../services';
import useAmplitude from './useAmplitude';

const useOpenRobotLid = (config: Record<string, unknown>) => {
  const { operationsApi } = useAppContext();
  const { logEvent } = useAmplitude();

  return useMutation({
    mutationFn: (robotSerial: string) => openRobotLid(operationsApi, logEvent, robotSerial),
    ...config,
  });
};

export default useOpenRobotLid;
