import { useAuth0 } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { FullStory } from '@fullstory/browser';
import amplitude, { AmplitudeClient } from 'amplitude-js';
import { useCallback } from 'react';

import { useAppContext } from 'src/context';
import { AmplitudeEvent, ERROR_EVENTS } from '../@types';
import { AMPLITUDE_API_KEY } from '../constants';
import { getLoadAttemptId } from '../utils';

interface IAmplitude {
  init: () => void;
  logEvent: (event: AmplitudeEvent) => void;
}

const useAmplitude = (): IAmplitude => {
  const { selectedDelivery, robotId } = useAppContext();
  const { user } = useAuth0();

  const init = useCallback((): void => {
    if (typeof window !== 'undefined') {
      const instance: AmplitudeClient = amplitude.getInstance();
      instance.init(AMPLITUDE_API_KEY, undefined, {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true,
        includeGclid: true,
      });
      setTimeout(() => {
        const session = instance.getSessionId();
        if (!session) {
          throw new Error('Amplitude: Failed to initialize');
        }
      }, 2000);
    }
  }, []);

  const logEvent = useCallback(
    ({ eventName, params }: AmplitudeEvent): void => {
      setTimeout(() => {
        const instance: AmplitudeClient = amplitude.getInstance();
        const initialized = instance.getSessionId();
        if (initialized) {
          const eventParams = {
            deliveryId: selectedDelivery?.id ?? '',
            robotId,
            merchantName: user?.name ?? '',
            merchantId: user?.sub ?? '',
            loadAttemptId: getLoadAttemptId(),
            datetime: new Date().toISOString(),
            ...params,
          };
          instance.logEvent(eventName, { ...eventParams });

          process.env.NODE_ENV === 'development' &&
            console.debug('[Amplitude] Logging', {
              eventName,
              params: eventParams,
            });

          if (ERROR_EVENTS.includes(eventName)) {
            FullStory('trackEvent', {
              name: eventName,
              properties: eventParams,
            });
            datadogLogs.logger.error(eventName, eventParams);
          }
        } else {
          throw new Error(`Amplitude: Failed to log event ${eventName}`);
        }
      });
    },
    [robotId, selectedDelivery, user?.name, user?.sub]
  );

  return {
    init,
    logEvent,
  };
};

export default useAmplitude;
