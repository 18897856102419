import { UseQueryOptions } from 'react-query';

import { LID_ACTIONS } from './robot';

export type PromiseValue<PromiseType> = PromiseType extends PromiseLike<infer Value>
  ? PromiseValue<Value>
  : PromiseType;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryConfig<FetcherFnType extends (...args: any) => unknown> = UseQueryOptions<
  PromiseValue<ReturnType<FetcherFnType>>
>;

export enum QUERY_KEYS {
  DELIVERIES = 'deliveries',
  ROBOTS = 'robots',
}

export type CommonRefetchIntervals =
  | '5 seconds'
  | '10 seconds'
  | '20 seconds'
  | '30 seconds'
  | '1 minute'
  | '5 minutes'
  | '10 minutes'
  | '15 minutes';

export const robotQueryKeys = {
  all: [QUERY_KEYS.ROBOTS] as const,
  load: (serialNo: string, attemptId: string) => [...robotQueryKeys.all, 'load', `${serialNo}/${attemptId}`] as const,
  toggle: (serialNo: string, action: LID_ACTIONS) =>
    [...robotQueryKeys.all, 'toggle', `${serialNo}/${action}`] as const,
};

export const operationsQueryKeys = {
  pii: (customerId: string) => ['pii', customerId],
  robotEta: (robotSerial: string) => ['robotEta', robotSerial],
};

export const deliveryQueryKeys = {
  all: [QUERY_KEYS.DELIVERIES] as const,
  list: (partnerIds: string[]) => [...deliveryQueryKeys.all, 'list', partnerIds] as const,
  partners: (auth0Id: string) => [...deliveryQueryKeys.all, 'partners', auth0Id],
  delivery: (deliveryId: string) => [...deliveryQueryKeys.all, 'delivery', deliveryId],
};
