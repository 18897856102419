import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';

import { Delivery } from '@coco/types/deliveries';

import { deliveryQueryKeys } from 'src/@types';
import { useAppContext } from 'src/context';

interface Params {
  deliveryId: string;
}

const fetchDeliveryV3 = async (deliveriesApi: AxiosInstance, deliveryId: string): Promise<Delivery> => {
  const { data } = await deliveriesApi.get(`/api/v3/delivery/${deliveryId}`);

  return data;
};

export default function useDeliveryV3({ deliveryId }: Params) {
  const { deliveriesApi } = useAppContext();

  return useQuery({
    queryKey: deliveryQueryKeys.delivery(deliveryId),
    queryFn: () => fetchDeliveryV3(deliveriesApi, deliveryId),
    enabled: !!deliveryId,
  });
}
