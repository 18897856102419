import { FullStory } from '@fullstory/browser';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { FS_EVENTS } from 'src/@types';
import { styled } from 'src/stitches.config';
import { Button } from './Button';
import Modal from './Modal';

interface Props {
  open: boolean;
  onClose: () => void;
  robotSerial: string;
}

const Copy = styled('p', {
  padding: '$1 $0',
  fontSize: '$5',
  fontWeight: '500',
  lineHeight: '1.5',
});

export const DifferentRobotAlert = ({ open, onClose, robotSerial }: Props) => {
  const searchParams = useSearchParams();
  const scannedRobotSerial = searchParams.get('cocoId');

  useEffect(() => {
    if (open) {
      FullStory('trackEvent', {
        name: FS_EVENTS.DIFFERENT_ROBOT_ALERT_OPEN,
        properties: {
          resolvedRobotSerial: robotSerial,
          scannedRobotSerial,
        },
      });
    }
  }, [open, robotSerial, scannedRobotSerial]);

  return (
    <Modal isOpen={open}>
      <Copy>
        Robot {robotSerial} has been assigned to this order. Opening lid of {robotSerial} now.
      </Copy>
      <Button onClick={onClose} style={{ marginTop: '1rem' }}>
        Got it!
      </Button>
    </Modal>
  );
};
