import { t } from 'i18next';

import { OpenLidError } from 'src/@types';
import { LOC_NS, OPEN_LID_ERRORS_KEYS } from 'src/i18n/types';

export const getErrorTypeFromErrorMessage = (errorMessage: string): OpenLidError => {
  if (errorMessage.includes('health check')) {
    return OpenLidError.BOT_UNHEALTHY;
  }

  if (errorMessage.includes('currently unavailable')) {
    return OpenLidError.BOT_UNAVAILABLE;
  }

  if (errorMessage.includes('manually grounded')) {
    return OpenLidError.BOT_GROUNDED;
  }

  if (errorMessage.includes('is offline')) {
    return OpenLidError.BOT_OFFLINE;
  }

  if (errorMessage.includes('on a trip')) {
    return OpenLidError.BOT_ON_TRIP;
  }

  if (errorMessage.includes('maintenance')) {
    return OpenLidError.BOT_NEEDS_MAINTENANCE;
  }

  if (errorMessage.includes('not in range')) {
    return OpenLidError.BOT_NOT_IN_RANGE;
  }

  return OpenLidError.OTHER;
};

export const openLidErrorsT = (key: OPEN_LID_ERRORS_KEYS, fallback: string) => {
  return t(`${LOC_NS.OPEN_LID_ERRORS}:${key}`, fallback);
};

export const getErrorCopy = (errorType: OpenLidError, hasDesignatedRobot: boolean) => {
  switch (errorType) {
    case OpenLidError.BOT_UNAVAILABLE:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.UNAVAILABLE, 'This Coco is currently unavailable.');
    case OpenLidError.BOT_OFFLINE:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.OFFLINE, 'This Coco is offline.');
    case OpenLidError.BOT_ON_TRIP:
      return hasDesignatedRobot
        ? "This delivery's Coco has not yet arrived."
        : openLidErrorsT(OPEN_LID_ERRORS_KEYS.ALREADY_ASSIGNED, 'This Coco is assigned to an order.');
    case OpenLidError.BOT_GROUNDED:
    case OpenLidError.BOT_NEEDS_MAINTENANCE:
    case OpenLidError.BOT_UNHEALTHY:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.FAILED_HEALTH_CHECK, 'This Coco did not pass its health check.');
    case OpenLidError.BOT_NOT_IN_RANGE:
      return hasDesignatedRobot
        ? openLidErrorsT(OPEN_LID_ERRORS_KEYS.NOT_IN_RANGE, "This delivery's Coco has not yet arrived.")
        : 'This Coco is not in range.';
    case OpenLidError.OTHER:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.FAILED_TO_OPEN, 'Lid failed to open.');
    default:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.FAILED_TO_OPEN, 'Lid failed to open.');
  }
};
